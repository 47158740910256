import { useState } from 'react';
import { Box } from '@mui/material';

import DayRender from './DayRender';
import { StringToDate, DateToString } from '../utils/dateConversions';
import { Course } from '../model/Course';
import './Calendar.css';

export enum modes {
  auto,
  one,
  three,
  week,
}

export const modesName = ['auto', 'one', 'three', 'week'];

export enum compactEnum {
  auto = -1,
  no = 0,
  yes = 1,
}

interface CalendarProps {
  result: Map<string, Array<Course>>;
  error: boolean;
  loading: boolean;
  mode: modes;
  compact: compactEnum;
  day: string;
  change: (direction: boolean) => any;
}

export default function Calendar(props: CalendarProps) {
  // swipe state

  let [offsetY, setOffsetY] = useState(0);
  let [offsetX, setOffsetX] = useState(0);

  let [startX, setStartX] = useState(-1);
  let [startY, setStartY] = useState(-1);

  let [movingX, setMovingX] = useState(false);
  let [movingY, setMovingY] = useState(false);

  let className = 'one-day-view';
  let nbDays = 1;
  switch (props.mode) {
    case modes.three:
      nbDays = 3;
      className = 'three-days-view';
      break;
    case modes.week:
      nbDays = 7;
      className = 'week-view';
      break;
  }

  let out = [];

  let currentDate = StringToDate(props.day);

  for (let d = 0; d < nbDays; ++d) {
    out.push(
      <DayRender
        mode={props.mode}
        key={d}
        compact={props.compact === compactEnum.yes}
        result={props.result}
        day={DateToString(currentDate)}
        column={d + 2}
      />
    );
    currentDate.setDate(currentDate.getDate() + 1);
  }

  let hoursMarks = [];
  for (let i = 2; i <= 50; i += 4) {
    const style = {
      gridRowStart: i,
      gridRowEnd: i,
    };
    hoursMarks.push(
      <span style={style} className="hourMark" key={i}>
        {7 + (i - 2) / 4}:00
      </span>
    );
  }

  if (props.error) return <div>erreur lors du chargement du calendrier</div>;

  const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

  const treshold = 3 * rem;

  // end of a swipe

  const endFunc = () => {
    if (!movingY) {
      if (offsetX > 0 && offsetX > treshold) {
        props.change(false);
      } else if (offsetX < -1 * treshold && offsetX < 0) {
        props.change(true);
      }
    }
    setOffsetX(0);
    setOffsetY(0);

    setMovingX(false);
    setMovingY(false);
  };

  // movement in a swipe

  const move = (clientX: number, clientY: number) => {
    if (!movingY) {
      if (Math.abs(clientX - startX) > rem) setOffsetX(clientX - startX);

      if (!movingX) {
        if (Math.abs(clientY - startY) > rem) setOffsetY(clientY - startY);

        if (Math.abs(offsetX) > rem || Math.abs(offsetY) > rem)
          if (Math.abs(offsetX) > Math.abs(offsetY)) setMovingX(true);
          else setMovingY(true);
      }
    }
  };

  // start of a swipe

  const start = (clientX: number, clientY: number) => {
    setStartX(clientX);
    setStartY(clientY);
  };

  // cancellation of a swipe

  const cancel = () => {
    setMovingX(false);
    setMovingY(false);

    setOffsetX(0);
    setOffsetY(0);
  };

  return (
    <Box id={'cal-view-refresh'}>
      <div
        className={className}
        onTouchCancel={() => cancel()}
        onTouchStart={(ev) => {
          start(ev.touches[0].clientX, ev.touches[0].clientY);
        }}
        onTouchMove={(ev) => move(ev.touches[0].clientX, ev.touches[0].clientY)}
        onTouchEnd={() => {
          endFunc();
        }}
      >
        {out.length > 0 ? (
          <div className="courses">
            {out}
            {hoursMarks}
          </div>
        ) : (
          <p className="no-course" key={'no-course'}>
            vous n’avez pas cours !
          </p>
        )}
      </div>
    </Box>
  );
}
