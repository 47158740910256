import { Box, Stack, Button, IconButton, ThemeOptions } from '@mui/material';
import { Calendar } from '../model/Calendar';
import CalendarForm from './CalendarForm';
import { ThemeSelector } from './ThemeSelector';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export function Settings({
  calendars: topCalendars,
  onValidate: setTopCalendars,
  currentTheme,
  customTheme,
  setTheme,
}: {
  calendars: Array<Calendar>;
  onValidate: (calendars: Array<Calendar>) => void;
  currentTheme: string;
  customTheme: ThemeOptions;
  setTheme: (custom_theme: ThemeOptions, current_theme: string) => void;
}) {
  const [calendars, setCalendars] = useState(() => {
    if (topCalendars.length === 0) return [new Calendar('', '')];
    else return topCalendars;
  });

  function addCalendar() {
    let calendar = new Calendar('', '');
    setCalendars([...calendars, calendar]);
  }

  function removeCalendar(indexToRemove: number) {
    setCalendars((old) => {
      return old.filter((v, index) => {
        return index !== indexToRemove;
      });
    });
  }

  function updateCalendar(index: number, newCalendar: Calendar) {
    setCalendars(
      calendars.map((oldCalendar, currentIndex) =>
        currentIndex === index ? newCalendar : oldCalendar
      )
    );
  }

  function onValidate() {
    let filteredCalendars = calendars.filter(
      (cal) => cal.name !== '' && cal.url !== ''
    );
    if (filteredCalendars.length > 0) {
      setTopCalendars(filteredCalendars);
    }
  }

  return (
    <Box
      id={'view'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50rem',
        margin: 'auto',
      }}
    >
      <h3> Calendriers </h3>
      <p className="url-label">
        Url du calendrier en ical (
        <a
          href={
            'https://gitlab.com/nilsponsard/oh-my-ade/-/wikis/Comment-obtenir-l%E2%80%99url-du-calendrier'
          }
        >
          comment l'obtenir
        </a>
        ) :
      </p>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: '100%', textAlign: 'left' }}
      >
        <Button variant="contained" onClick={onValidate}>
          Sauvegarder
        </Button>
        <Button variant="outlined" onClick={() => setCalendars(topCalendars)}>
          Annuler les changements
        </Button>
      </Stack>

      <Stack spacing={2} sx={{ marginTop: 2 }}>
        {calendars.map((calendar, index) => (
          <CalendarForm
            key={calendar.uuid}
            calendar={calendar}
            onChange={(c) => updateCalendar(index, c)}
            onDelete={() => removeCalendar(index)}
          />
        ))}
      </Stack>

      <IconButton
        onClick={() => addCalendar()}
        color="primary"
        sx={{
          margin: 'auto',
          width: '4rem',
          height: '4rem',
          marginTop: '2rem',
          fontSize: '2rem',
          marginBottom: '2rem',
        }}
        size="large"
      >
        <AddCircleIcon fontSize="large" />
      </IconButton>
      <ThemeSelector
        currentTheme={currentTheme}
        customTheme={customTheme}
        setTheme={setTheme}
      />
    </Box>
  );
}
