import { Course } from '../model/Course';

export function getIndexNextCourse(courses: Array<Course>) {
  let currentTime = new Date(Date.now());
  let min = 24 * 60 * 60 * 1000; // 24h
  let minIndex = null;
  for (let index = 0; index < courses.length; ++index) {
    let diff = courses[index].end.getTime() - currentTime.getTime();
    if (diff > 0 && diff < min) {
      min = diff;
      minIndex = index;
    }
  }
  return minIndex;
}
