export class SharedCalendar {
  name: string;
  url: string;

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
  }

  static fromBase64(base64: string): SharedCalendar {
    const json = atob(base64);
    const obj = JSON.parse(json);
    return new SharedCalendar(obj.name, obj.url);
  }

  toBase64(): string {
    const json = JSON.stringify(this);
    return btoa(json);
  }
}
