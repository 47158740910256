import { Box, Stack, TextField, IconButton } from '@mui/material';
import { Calendar } from '../model/Calendar';

import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import { SharedCalendar } from '../model/SharedCalendar';
interface CalendarFormProps {
  calendar: Calendar;
  onChange: (newCal: Calendar) => void;
  onDelete: () => void;
}

function getSharingUrl(calendar: Calendar) {
  const shareCalendar = new SharedCalendar(calendar.name, calendar.url);

  const url = window.location.origin + '/shared/' + shareCalendar.toBase64();

  return url;
}

export default function CalendarForm({
  calendar,
  onChange,
  onDelete,
}: CalendarFormProps) {
  const handleShare = () => {
    const url = getSharingUrl(calendar);
    navigator.clipboard.writeText(url);
  };

  return (
    <Box
      sx={{
        width: '100%',

        padding: '1rem',

        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #555',
        borderRadius: '0.5rem',
      }}
    >
      <Stack spacing={2} sx={{ flexGrow: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Nom du calendrier"
          error={calendar.name === ''}
          onChange={(event) => {
            onChange({
              ...calendar,
              name: event.target.value,
            });
          }}
          defaultValue={calendar.name}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="URL du calendrier"
          error={calendar.url === ''}
          onChange={(event) => {
            onChange({
              ...calendar,
              url: event.target.value,
            });
          }}
          defaultValue={calendar.url}
        />
      </Stack>
      <Stack
        spacing={2}
        sx={{
          marginLeft: '1rem',
          marginTop: 'auto',
          marginBottom: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton onClick={handleShare}>
          <ShareIcon />
        </IconButton>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}
