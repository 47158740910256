import * as converter from '../utils/dateConversions';
import { Calendar, parseCalendarContent } from './Calendar';
import { Course } from './Course';

const PROXY_URL =
  process.env.REACT_APP_PROXY_URL ||
  'https://sautax.alwaysdata.net/p/api/proxy';

export interface ResultWithStatus<T> {
  result: T;
  status: string;
  error: boolean;
}

export function bakeUrl(originalUrl: string) {
  let today = new Date(Date.now());
  let firstDate = new Date(today);
  firstDate.setDate(firstDate.getDate() - 365);
  let lastDate = new Date(today);
  lastDate.setDate(lastDate.getDate() + 365);
  let url = '';
  if (originalUrl.length > 0) {
    if (originalUrl.includes('firstDate')) {
      url = originalUrl.substring(0, originalUrl.lastIndexOf('&firstDate='));
      url +=
        '&firstDate=' +
        converter.DateToAdeString(firstDate) +
        '&lastDate=' +
        converter.DateToAdeString(lastDate);
    } else {
      url = originalUrl;
    }
    return url;
  }
  return null;
}

export async function fetchUrl(url: string): Promise<ResultWithStatus<string>> {
  return fetch(PROXY_URL, {
    body: url,
    method: 'POST',
  })
    .then((value) => value.text())
    .then((result) => {
      return { result, status: '', error: false };
    })
    .catch(() => {
      return { result: '', status: 'error when fetching', error: true };
    });
}

export async function makeRequest(
  bakedUrl: string
): Promise<ResultWithStatus<string>> {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      console.log('fetch timeout');
      // stop after the timeout
      resolve({
        result: '',
        status: 'timeOut',
        error: true,
      });
    }, 10000); // timeout after 10 seconds
    if (bakedUrl) {
      // remove the warning
      fetchUrl(bakedUrl).then((res) => {
        clearTimeout(timer);
        resolve(res);
      });
    }
  });
}

export async function getCourses(
  calendar: Calendar,
  forceFetch = false
): Promise<ResultWithStatus<Map<string, Array<Course>>>> {
  let bakedUrl = bakeUrl(calendar.url);

  const today = new Date(Date.now());

  if (bakedUrl === null) {
    return { result: new Map(), status: 'invalidUrl', error: true };
  }

  let madeRequest = false;
  if (
    forceFetch ||
    !calendar.lastFetchContent ||
    today.getTime() - calendar.lastFetchDate.getTime() > 1000 * 60 * 60 * 12
  ) {
    // if cache is more than 12h old
    madeRequest = true;

    let fetchRes = await makeRequest(bakedUrl);

    if (!fetchRes.error) {
      let parseRes = await parseCalendarContent(fetchRes.result);
      if (!parseRes.error) {
        calendar.lastFetchContent = fetchRes.result;
        calendar.lastFetchDate = new Date();
        return { result: parseRes.result, status: 'fetch', error: false };
      }
    }
  }

  if (calendar.lastFetchContent) {
    let parseRes = await parseCalendarContent(calendar.lastFetchContent);
    if (!parseRes.error) {
      if (madeRequest) {
        return {
          result: parseRes.result,
          status: 'fetchError+cache',
          error: false,
        };
      } else {
        return { result: parseRes.result, status: 'cache', error: false };
      }
    }
  }

  return { result: new Map(), status: 'error', error: true };
}
