import { async as ICALAsync } from '@nponsard/ical';
import { Course, parseCourse } from './Course';
import { ResultWithStatus } from './request';
import * as converter from '../utils/dateConversions';

export type CalendarMap = Map<string, Array<Course>>;

export class Calendar {
  uuid: string;
  name: string;
  url: string;
  lastFetchContent: string | null = null;
  lastFetchDate: Date = new Date(0);

  constructor(name: string, url: string) {
    this.name = name;
    this.url = url;
    this.uuid = crypto.randomUUID();
  }
}

export async function parseCalendarContent(
  content: string
): Promise<ResultWithStatus<CalendarMap>> {
  let result = new Map<string, Array<Course>>();
  try {
    let icalParsed = await ICALAsync.parseICS(content);

    if (Object.keys(icalParsed).length === 0) {
      return { result, status: 'empty', error: true };
    }

    // index by day
    for (const key in icalParsed) {
      if (!icalParsed.hasOwnProperty(key)) continue;

      let ev = icalParsed[key];

      if (ev.type !== 'VEVENT') continue;

      let cours = parseCourse(ev);
      let day = converter.DateToString(cours.start);
      let currentArray = result.get(day);

      if (!currentArray) currentArray = [];
      currentArray.push(cours);
      result.set(day, currentArray);
    }
  } catch (e) {
    console.error(e);
    return { result, status: 'parsingError', error: true };
  }

  return { result, status: '', error: false };
}
