import { VEvent } from '@nponsard/ical';

function filterDescription(description: string) {
  const fields = description.split('\n');

  let group = fields.slice(2, -2).join(' ');

  group = group.replace(/(Validé|A valider|Transféré)/g, ' ');

  const lastModification = fields[fields.length - 1];

  return { group, lastModification };
}

export interface Course {
  name: string;
  location: string;
  group: string;
  lastModification: string;
  start: Date;
  end: Date;
  uid: string;
}

export function parseCourse(ev: VEvent): Course {
  const { uid, summary: name, start, end, ...rest } = ev;
  const { group, lastModification } = filterDescription(rest.description);
  const location =
    rest.location === '' ? '(pas de salle définie)' : rest.location;

  return {
    group,
    lastModification,
    location,
    start,
    end,
    uid,
    name,
  };
}
