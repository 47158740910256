import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Box,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { modes } from './Calendar';
import { ModeIcon } from './ModeIcon';
import { StringToDate } from '../utils/dateConversions';
import { Calendar } from '../model/Calendar';

interface AppBarProps {
  showSettings: boolean;
  setShowSettings: (show: boolean) => void;

  currentMode: modes;
  setCurrentMode: (mode: modes) => void;

  currentDay: string;
  goFoward: () => void;
  goBack: () => void;
  goToday: () => void;

  refresh: () => void;

  calendars: Calendar[];
  selectedCalendarName: string;
  setSelectedCalendar: (id: string) => void;

  loading: boolean;
}

export default function CustomAppBar(props: AppBarProps) {
  return (
    <AppBar position="sticky">
      <Toolbar color="primary">
        {props.showSettings ? (
          <>
            {props.calendars.length >= 1 ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="retour"
                  sx={{ mr: 2 }}
                  onClick={() => props.setShowSettings(false)}
                >
                  <ArrowBackIcon />
                </IconButton>

                <Typography
                  variant="h6"
                  color="inherit"
                  component="div"
                  sx={{ flexGrow: 1 }}
                >
                  Paramètres
                </Typography>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {props.currentMode === modes.one ? (
                <>
                  {StringToDate(props.currentDay).toLocaleDateString('fr', {
                    day: 'numeric',
                    month: 'short',
                    weekday: 'short',
                  })}
                </>
              ) : (
                <>Oh my ade</>
              )}
            </Typography>

            <FormControl>
              <Select
                value={props.selectedCalendarName}
                onChange={(event) => {
                  props.setSelectedCalendar(event.target.value);
                }}
              >
                {props.calendars.map((calendar, index) => (
                  <MenuItem
                    key={`${calendar.name}${index}`}
                    value={calendar.name}
                  >
                    {calendar.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <IconButton aria-label="jour précédent" onClick={props.goBack}>
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton aria-label="Aujourd’hui" onClick={props.goToday}>
              <CalendarTodayIcon />
            </IconButton>
            <IconButton aria-label="jour suivant" onClick={props.goFoward}>
              <NavigateNextIcon />
            </IconButton>
            <IconButton
              aria-label="changer l’affichage"
              onClick={() => {
                let newMode = props.currentMode + 1;
                if (newMode > modes.week) newMode = modes.auto;

                props.setCurrentMode(newMode);
              }}
            >
              <ModeIcon mode={props.currentMode} />
            </IconButton>
            <IconButton aria-label="actualiser" onClick={props.refresh}>
              <RefreshIcon />
            </IconButton>

            <IconButton
              edge="end"
              aria-label="paramètres"
              onClick={() => props.setShowSettings(true)}
            >
              <SettingsSharpIcon />
            </IconButton>
          </>
        )}
      </Toolbar>
      {props.loading ? (
        <LinearProgress />
      ) : (
        <Box sx={{ height: '4px', width: '100%' }}></Box>
      )}
    </AppBar>
  );
}
