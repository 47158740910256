import { ThemeOptions } from '@mui/material';

const default_theme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#3949ab',
    },
  },
};

// Juste un theme de base pour se faire remplacer
const custom_theme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#121212',
      paper: '#121212',
    },
  },
};

const dacula_theme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#bd93f9',
    },
    background: {
      paper: '#44475a',
      default: '#282a36',
    },
    divider: '#44475a',
  },
};

const nord_theme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#88c0d0',
    },
    background: {
      paper: '#3b4252',
      default: '#2e3440',
    },
    divider: '#4c566a',
  },
};

const themes: Record<string, ThemeOptions> = {
  default: default_theme,
  dracula: dacula_theme,
  nord: nord_theme,
  custom: custom_theme,
};

export default themes;
