import {
  Select,
  MenuItem,
  Box,
  TextField,
  ThemeOptions,
  SimplePaletteColorOptions,
  Typography,
  Button,
} from '@mui/material';
import { Stack } from '@mui/material';
import default_themes from '../utils/themes';
import { useState } from 'react';

function CustomThemeForm(props: {
  customTheme: ThemeOptions;
  setCustomTheme: (t: ThemeOptions) => void;
}) {
  let primary = props.customTheme.palette?.primary as
    | SimplePaletteColorOptions
    | undefined;
  let primaryMain = '';
  if (primary) {
    primaryMain = primary.main;
  }

  const [theme, setTheme] = useState<ThemeOptions>(props.customTheme);

  function applyTheme() {
    props.setCustomTheme(theme);
  }

  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <TextField
        fullWidth
        variant="outlined"
        label="Couleur de fond"
        onChange={(event) => {
          setTheme({
            ...props.customTheme,
            palette: {
              ...props.customTheme.palette,
              background: {
                default: event.target.value,
                paper: props.customTheme.palette?.background?.paper,
              },
            },
          });
        }}
        defaultValue={props.customTheme.palette?.background?.default || ''}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Couleur de la Toolbar"
        onChange={(event) => {
          setTheme({
            ...props.customTheme,
            palette: {
              ...props.customTheme.palette,
              background: {
                paper: event.target.value,
                default: props.customTheme.palette?.background?.default,
              },
            },
          });
        }}
        defaultValue={props.customTheme.palette?.background?.paper || ''}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Couleur primaire"
        onChange={(event) => {
          setTheme({
            ...props.customTheme,
            palette: {
              ...props.customTheme.palette,
              primary: {
                ...props.customTheme.palette?.primary,
                main: event.target.value,
              },
            },
          });
        }}
        defaultValue={primaryMain}
      />
      <Button variant="contained" onClick={applyTheme}>
        Appliquer
      </Button>
    </Stack>
  );
}

interface ThemeSelectorProps {
  currentTheme: string;
  customTheme: ThemeOptions;
  setTheme: (custom_theme: ThemeOptions, current_theme: string) => void;
}

export function ThemeSelector(props: ThemeSelectorProps) {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #555',
        borderRadius: '0.5rem',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6">Thème</Typography>
        <Select
          onChange={(e) => {
            props.setTheme(props.customTheme, e.target.value);
          }}
          value={props.currentTheme || 'default'}
        >
          {Object.keys(default_themes).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>

        {props.currentTheme === 'custom' && (
          <CustomThemeForm
            customTheme={props.customTheme}
            setCustomTheme={(t) => {
              console.log(t.palette?.background?.default);
              props.setTheme(t, 'custom');
            }}
          />
        )}
      </Stack>
    </Box>
  );
}
