export function getStatusMessage(
  coursesStatus: string,
  fetchDate: Date
): string {
  if (coursesStatus === 'fetch') {
    return 'Nouvelle version chargée';
  } else if (coursesStatus === 'error') {
    return 'Erreur lors du chargement';
  } else {
    let date =
      ' du ' +
      fetchDate.toLocaleString('fr', {
        day: 'numeric',
        month: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    if (coursesStatus === 'empty') {
      return 'Calendrier vide, affichage de la version du' + date;
    }
    if (coursesStatus === 'cache') {
      return 'Affichage d’une version en cache' + date;
    } else if (coursesStatus === 'fetchError+cache') {
      return 'Erreur, affichage d’une version en cache' + date;
    } else {
      return '';
    }
  }
}
