import { modes } from './Calendar';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';

export function ModeIcon(props: { mode: modes }) {
  switch (props.mode) {
    case modes.three:
      return <ViewColumnIcon />;
    case modes.one:
      return <ViewDayIcon />;
    case modes.week:
      return <CalendarViewWeekIcon />;
    default:
      return <HdrAutoIcon />;
  }
}
