import Course from './Course';
import * as converter from '../utils/dateConversions';
import { getIndexNextCourse } from '../utils/nextCourse';
import { DateToString } from '../utils/dateConversions';
import { modes } from './Calendar';
import './Calendar.css';
import { Course as CourseModel } from '../model/Course';

interface CaledarProps {
  result: Map<string, Array<CourseModel>>;
  day: string;
  compact: boolean;
  column: number;
  mode: modes;
}

export default function DayRender(props: CaledarProps) {
  const todayStr = DateToString(new Date(Date.now()));
  let res = props.result.get(props.day);
  let out = [];

  const dateStyle = {
    gridColumnStart: props.column,
  };

  out.push(
    <div key="day" className={'day-date'} style={dateStyle}>
      {converter.StringToDate(props.day).toLocaleDateString('fr', {
        day: 'numeric',
        month: 'short',
        weekday: 'short',
      })}
    </div>
  );

  if (res && res.length > 0) {
    res = res.sort((a, b) => {
      return a.start.getTime() - b.start.getTime();
    });

    let currentCourseIndex = getIndexNextCourse(res);
    for (let index = 0; index < res.length; ++index) {
      let flatTop = false;
      let flatBot = false;

      if (
        index > 0 &&
        res[index - 1].end.getTime() === res[index].start.getTime()
      ) {
        flatTop = true;
      }
      if (
        index + 1 < res.length &&
        res[index + 1].start.getTime() === res[index].end.getTime()
      ) {
        flatBot = true;
      }

      out.push(
        <Course
          key={res[index].uid}
          input={res[index]}
          current={index === currentCourseIndex && todayStr === props.day}
          compact={props.compact}
          column={props.column}
          flatBot={flatBot}
          flatTop={flatTop}
        />
      );
    }
  }

  return <>{out}</>;
}
