import { Course as CourseModel } from '../model/Course';

// some dark material colors :

const colors = [
  '#C62828',
  '#C51162',
  '#7B1FA2',
  '#4A148C',
  '#5E35B1',
  '#311B92',
  '#303F9F',
  '#1A237E',
  '#0277BD',
  '#01579B',
  '#00796B',
  '#004D40',
  '#1B5E20',
  '#BF360C',
  '#3E2723',
  '#212121',
  '#455A64',
  '#757575',
  '#263238',
  '#37474F',
];

interface CourseProps {
  input: CourseModel;
  current: boolean;
  compact: boolean;
  column: number;
  flatTop: boolean;
  flatBot: boolean;
}

export default function Course(props: CourseProps) {
  const dateOptions: Intl.DateTimeFormatOptions = {
    hour12: false,
    minute: '2-digit',
    hour: '2-digit',
  };
  const courseNumber = parseInt(props.input.name.substring(2, 3));

  let color = colors[0];
  if (!isNaN(courseNumber) && courseNumber >= 0) {
    let divided = courseNumber / 10;
    let big = Math.floor(divided);
    let little = (divided - big) * 10;
    let n = big + little;

    if (n > 10) {
      n = Math.round(n) - 10;
    }

    color = colors[n % colors.length];
  } else {
    // if the name is not in the expected format
    let sum = 0;
    let end = props.input.name.length;
    if (end > 10) {
      end = end - 5; // don't get the last 5 chars if the name is long, as it usually contains the group
    }
    // compute the sum
    for (let i = 0; i < end; ++i) {
      sum += props.input.name.charCodeAt(i);
    }
    color = colors[sum % colors.length];
  }

  let gridRowStart =
    (props.input.start.getHours() - 7) * 4 +
    Math.floor(props.input.start.getMinutes() / 15) +
    2;
  let gridRowEnd =
    (props.input.end.getHours() - 7) * 4 +
    Math.floor(props.input.end.getMinutes() / 15) +
    2;

  const courseStyle = {
    backgroundColor: color,
    gridRowStart,
    gridRowEnd,
    gridColumnStart: props.column,
  };
  let currentClass = 'course';
  if (props.current) currentClass += ' current-course';

  if (props.compact) currentClass += ' course-compact';

  if (props.flatBot) currentClass += ' flat-bot';
  if (props.flatTop) currentClass += ' flat-top';

  return (
    <div className={currentClass} key={props.input.uid} style={courseStyle}>
      <div className={'course-start'}>
        {props.input.start.toLocaleString('fr-FR', dateOptions)}{' '}
      </div>
      {/*<div className={'course-pipe'}>|</div>*/}
      <div className={'course-end'}>
        {props.input.end.toLocaleString('fr-FR', dateOptions)}
      </div>
      <div className={'course-infos'}>
        <div className={'course-name'}>{props.input.name}</div>
        <div className={'course-location'}>{props.input.location}</div>
        <div className={'course-group'}>{props.input.group}</div>
      </div>
    </div>
  );
}
