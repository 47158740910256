import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { Component } from 'react';
import './App.css';
import Calendar, { compactEnum, modes } from './components/Calendar';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import { getStatusMessage } from './utils/statusMessage';
import * as converter from './utils/dateConversions';
import {
  Calendar as CalendarModel,
  parseCalendarContent,
} from './model/Calendar';
import CustomAppBar from './components/AppBar';
import { SharedCalendar } from './model/SharedCalendar';
import { Course } from './model/Course';
import { getCourses } from './model/request';
import default_themes from './utils/themes';
import { Settings } from './components/Settings';

interface AppState {
  calendars: Array<CalendarModel>;
  calendarName: string;
  result: Map<string, Array<Course>>;
  coursesStatus: string;
  loading: boolean;
  mode: modes;
  compact: compactEnum;
  showSnack: boolean;
  currentDay: string;
  settings: boolean;
  current_theme: string;
  custom_theme: ThemeOptions;
}

function calculateSettings(mode: modes, day: string) {
  const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const screenWidthRem = window.innerWidth / rem;
  const isLarger = screenWidthRem > 130;
  const isLarge = screenWidthRem > 105;
  const isMedium = screenWidthRem > 60;

  if (mode === modes.auto) {
    mode = modes.one;
    if (isMedium) mode = modes.three;
    if (isLarge) mode = modes.week;
  }

  let compact = compactEnum.no;
  if (!isLarger && mode === modes.week) compact = compactEnum.yes;
  if (!isMedium && mode === modes.three) compact = compactEnum.yes;

  let nbDays: number;

  switch (mode) {
    case modes.three:
      nbDays = 3;
      break;
    case modes.week:
      nbDays = 7;

      //-- Set at the begining of the week

      let selectedDate = converter.StringToDate(day);
      const dayOfWeek = selectedDate.getDay();
      let diff =
        selectedDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
      day = converter.DateToString(new Date(selectedDate.setDate(diff)));
      break;

    case modes.one:
    default:
      nbDays = 1;
      break;
  }

  return { day, nbDays, mode, compact };
}

function getOldStorageCalendar(calendarName: string): CalendarModel | null {
  let calendarUrl = localStorage.getItem('last_url');
  if (calendarUrl == null) return null;
  // if there is data from the old format
  let calendar = new CalendarModel(calendarName, calendarUrl);
  let lastFetchDate = localStorage.getItem('last_fetch_date');
  if (lastFetchDate) {
    calendar.lastFetchDate = new Date(lastFetchDate);
    calendar.lastFetchContent = localStorage.getItem('last_fetch_content');
  }
  return calendar;
}

class App extends Component<any, AppState> {
  constructor(props: any) {
    super(props);

    let calendars: Array<CalendarModel> = new Array<CalendarModel>();
    let calendarsStr = localStorage.getItem('calendars');
    let calendarName = '';

    let customThemeStr = localStorage.getItem('custom_theme');
    let custom_theme;

    if (!customThemeStr) {
      let themesStr = localStorage.getItem('themes');
      if (themesStr) {
        let themes_local = JSON.parse(themesStr);
        custom_theme = themes_local['custom'];
      }
      custom_theme = default_themes['custom'];

      localStorage.setItem('custom_theme', JSON.stringify(custom_theme));
    } else {
      custom_theme = JSON.parse(customThemeStr);
    }

    let currentThemeStr = localStorage.getItem('current_theme');
    if (!currentThemeStr) {
      localStorage.setItem('current_theme', 'default');
      currentThemeStr = 'default';
    }

    if (calendarsStr) {
      calendars = JSON.parse(calendarsStr);
      calendars.forEach((calendar: CalendarModel) => {
        // for calendars added with 2.4.0 and earlier, set an uuid
        if (calendar.uuid === undefined) calendar.uuid = crypto.randomUUID();

        calendar.lastFetchDate = new Date(calendar.lastFetchDate);
        return calendar;
      });

      if (calendars.length !== 0) {
        calendarName = localStorage.getItem('last_name') || '';
        if (!calendars.find((element) => element.name === calendarName)) {
          calendarName = calendars[0].name;
        }
      }
    } else {
      calendars = new Array<CalendarModel>();
      calendarName = 'Sans nom';
      let calendar = getOldStorageCalendar(calendarName);
      if (calendar !== null) calendars.push(calendar);

      // On met à jour le stockage, et on supprime l'ancien format
      localStorage.clear();
    }

    if (window.location.pathname.startsWith('/shared/')) {
      try {
        // get the base64 encoded data
        const data = window.location.pathname.replace('/shared/', '');

        // decode the data
        const calData = SharedCalendar.fromBase64(data);

        // create a new calendar

        const calendar = new CalendarModel(calData.name, calData.url);

        // add it to the list of calendars
        calendars.push(calendar);

        if (calendars.length === 1) {
          calendarName = calendar.name;
        }
      } catch (e) {
        console.error(e);
      }

      window.history.pushState({}, '', '/');
    }

    this.saveCalendar(calendarName, calendars);

    let settings = calendars.length === 0;

    this.state = {
      calendarName,
      calendars,
      currentDay: converter.DateToString(new Date(Date.now())),
      showSnack: false,
      result: new Map<string, Array<Course>>(),
      coursesStatus: 'none',
      loading: false,
      mode: modes.auto,
      compact: compactEnum.auto,
      settings,
      current_theme: currentThemeStr,
      custom_theme,
    };
  }

  saveCalendar(lastName: string, calendar: CalendarModel[]) {
    localStorage.setItem('last_name', lastName);
    localStorage.setItem('calendars', JSON.stringify(calendar));
  }

  getCalendar(name: string = this.state.calendarName): CalendarModel {
    let cal = this.state.calendars.find((el) => el.name === name);
    if (cal) return cal;
    throw new Error('no calendar associated with name ' + name);
  }

  getTheme() {
    if (this.state.current_theme === 'custom') {
      return this.state.custom_theme;
    } else {
      return default_themes[this.state.current_theme];
    }
  }

  setTheme(custom_theme: ThemeOptions, current_theme: string) {
    localStorage.setItem('current_theme', current_theme);
    localStorage.setItem('custom_theme', JSON.stringify(custom_theme));

    this.setState({
      custom_theme,
      current_theme,
    });
  }

  async componentDidMount() {
    window.addEventListener('resize', () => {
      this.forceUpdate();
    });
    window.addEventListener('keydown', (ev) => this.handleKey(ev));

    if (!this.state.settings) {
      await this.populate();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      this.forceUpdate();
    });
    window.removeEventListener('keydown', (ev) => this.handleKey(ev));
  }

  handleKey(ev: KeyboardEvent) {
    if (ev.key === 'ArrowRight') {
      this.goFoward();
    }
    if (ev.key === 'ArrowLeft') {
      this.goBack();
    }
  }

  goToday() {
    this.setState({
      currentDay: converter.DateToString(new Date(Date.now())),
    });
  }

  goFoward() {
    const { nbDays } = calculateSettings(
      this.state.mode,
      this.state.currentDay
    );
    this.setState({
      currentDay: converter.OffsetDay(nbDays, this.state.currentDay),
    });
  }

  goBack() {
    const { nbDays } = calculateSettings(
      this.state.mode,
      this.state.currentDay
    );
    this.setState({
      currentDay: converter.OffsetDay(-nbDays, this.state.currentDay),
    });
  }

  async populate(forceRefresh = false) {
    this.setState({ loading: true });

    let calendar = this.getCalendar(this.state.calendarName);
    console.log('fetching url : ' + calendar.url);

    if (calendar.lastFetchContent) {
      this.setState({
        result: (await parseCalendarContent(calendar.lastFetchContent)).result,
      });
    }

    let output = await getCourses(calendar, forceRefresh);
    // this.state.calendars potencially changed

    if (output.status === 'fetch') {
      localStorage.setItem('calendars', JSON.stringify(this.state.calendars));
    }

    let result = output.result;

    if (output.error) {
      console.log('An error occured while fetching');
    } else {
      console.log('using ' + output.status + ' ressource');
    }

    this.setState({
      calendars: this.state.calendars,
      result,
      coursesStatus: output.status,
      loading: false,
      showSnack: true,
    });
  }

  onValidateHandler(calendars: Array<CalendarModel>) {
    if (calendars.length === 0) return;

    let calendarName = this.state.calendarName;
    if (!calendars.find((element) => element.name === calendarName)) {
      calendarName = calendars[0].name;
    }

    calendars = calendars.filter(
      (element) => element.name != null && element.url != null
    );

    this.saveCalendar(calendarName, calendars);

    this.setState(
      { calendars, calendarName, loading: true, settings: false },
      () => {
        this.populate(true);
      }
    );
  }

  handleClose(_: any, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ showSnack: false });
  }

  render() {
    const { day, nbDays, mode, compact } = calculateSettings(
      this.state.mode,
      this.state.currentDay
    );

    const theme = createTheme(this.getTheme());

    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100vh',
            m: 0,
            p: 0,
          }}
        >
          <CustomAppBar
            calendars={this.state.calendars}
            currentDay={this.state.currentDay}
            currentMode={this.state.mode}
            goBack={() => this.goBack()}
            goFoward={() => this.goFoward()}
            goToday={() => this.goToday()}
            loading={this.state.loading}
            refresh={() => this.populate(true)}
            selectedCalendarName={this.state.calendarName}
            setSelectedCalendar={(name) =>
              this.setState({ calendarName: name }, () => this.populate())
            }
            setCurrentMode={(mode) => this.setState({ mode })}
            setShowSettings={(show) => this.setState({ settings: show })}
            showSettings={this.state.settings}
          />
          <div className="App">
            <div id={'content'}>
              {this.state.settings ? (
                <Settings
                  calendars={this.state.calendars}
                  onValidate={(calendars: Array<CalendarModel>) =>
                    this.onValidateHandler(calendars)
                  }
                  currentTheme={this.state.current_theme}
                  customTheme={this.state.custom_theme}
                  setTheme={(
                    custom_theme: ThemeOptions,
                    current_theme: string
                  ) => this.setTheme(custom_theme, current_theme)}
                />
              ) : (
                <>
                  {' '}
                  <Calendar
                    day={day}
                    error={this.state.coursesStatus === 'error'}
                    result={this.state.result}
                    loading={this.state.loading}
                    mode={mode}
                    compact={compact}
                    change={(direction) => {
                      this.setState({
                        currentDay: converter.OffsetDay(
                          (direction ? 1 : -1) * nbDays,
                          this.state.currentDay
                        ),
                      });
                    }}
                  />
                  <Snackbar
                    open={this.state.showSnack}
                    autoHideDuration={6000}
                    onClose={(event) => this.handleClose(event)}
                    message={getStatusMessage(
                      this.state.coursesStatus,
                      this.getCalendar().lastFetchDate
                    )}
                  />
                </>
              )}
            </div>

            <Typography component="div" sx={{ mt: '2em' }} className={'footer'}>
              Version {process.env.REACT_APP_VERSION} |{' '}
              <a href={'https://gitlab.com/nilsponsard/oh-my-ade'}>
                Code source
              </a>
            </Typography>
          </div>
        </Box>
      </ThemeProvider>
    );
  }
}

export default App;
